import React        from 'react';
import Header       from './microComponents/Header';
import LayeredWaves from "./microComponents/LayeredWaves.js";
import { Container, Row, Col }   from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import { speakers } from './utils/content.js'
import { useState } from 'react';

const Speakers = () => {
  const [more, setMore] = useState(false)

  const Toggle = () => {
    setMore(!more)
  }

  const max = {
    name: "Max Fosh",
    tag: "YouTuber",
    image: "max-fosh.png",
    desc_short: "“Hi, my name is Max Fosh and I am a YouTuber. Well, that is what I am to everyone apart from my immediate family. They would probably class me as unemployed. I have 1.4million subscribers on my channel and my videos have accrued over 350million views worldwide. I started making videos during my final year at University. It started a series called StreetSmart in which I interviewed students on nights out. It was ‘breathtakingly fresh content’ which cemented me as a ‘visionary pioneer’ of the man-on-the-street genre (these could be real quotes, I’m not sure)...”",
    desc_long: "“Hi, my name is Max Fosh and I am a YouTuber. Well, that is what I am to everyone apart from my immediate family. They would probably class me as unemployed. I have 1.4million subscribers on my channel and my videos have accrued over 350million views worldwide. I started making videos during my final year at University. It started a series called StreetSmart in which I interviewed students on nights out. It was ‘breathtakingly fresh content’ which cemented me as a ‘visionary pioneer’ of the man-on-the-street genre (these could be real quotes, I’m not sure). The real reason I started my own series was mainly because the Uni TV channel already had enough presenters on their show, so I started my own. Following the first episode filmed in October 2017, I relentlessly made StreetSmart episodes and I made approximately 25 episodes before leaving Uni. After graduating, I took StreetSmart on tour round 14 UK Universities. Following this, I worked in a bar for 5 months while working on the channel. I met two YouTubers called Zac and Jay during this time, and in February 2018 we made a video together which went truly viral; I gained 50,000 subscribers virtually overnight. Since then, I have grown steadily and have the greatest job in the world. Being professionally silly.”"
  }

  return (
    <div>
      <div className="main-container">
          <Header />
      </div>
      <div className="speakers-page">
        <Container className="container-speakers-top">
          <Row className="heading align-items-center">
            <Col sm={12} md={12} lg={12} xl={12} className="speakers-col img">
              <div className="speaker-header">
                <h1>TEDxOxford 2023 Speakers</h1>
              </div>
            </Col>
            <Col sm={12} md={9} lg={8} xl={5} className="speakers-col img">
              <div className="speaker-img">
                <img src={"/speakers/" + max.image} alt={max.name}/>
                <div className="shadow-overlay"></div>
              </div>
            </Col>
            <Col sm={12} md={9} lg={8} xl={7} className="speakers-col details">
              <div className="speaker-details">
                <h2>{max.name}</h2>
                <h3>{max.tag}</h3>
                {
                  !more ?
                  <>
                  <p>{max.desc_short}</p>
                  <span className={'toggle-view'} onClick={() => Toggle()}>Show More</span>
                  </>
                  :
                  <>
                  <p>{max.desc_long}</p>
                  <span className={'toggle-view'} onClick={() => Toggle()}>Show Less </span>
                  </>
                }
              </div>
            </Col>
            {speakers.map(speaker => (
              <>
                <Col sm={12} md={9} lg={8} xl={5} className="speakers-col img">
                <div className="speaker-img">
                  <img src={"/speakers/" + speaker.image} alt={speaker.name}/>
                  <div className="shadow-overlay"></div>
                </div>
              </Col>
              <Col sm={12} md={9} lg={8} xl={7} className="speakers-col details">
                <div className="speaker-details">
                  <h2>{speaker.name}</h2>
                  <h3>{speaker.tag}</h3>
                  <p>{speaker.desc}</p>
                </div>
              </Col>
              </>
            ))}
          </Row>
        </Container>
      </div>
      <LayeredWaves />
    </div>
    
  );
}

export default Speakers;
  