import React from "react";

const TEDtitle = ({first, second}) => {
  return (
    <div className="ted-title">
      <div className="title-container">
        <div className="first-word">
          <h1>{first}</h1>
        </div>
        <div className="second-word">
          <h1>{second}</h1>
        </div>
      </div>
    </div>
  );
};

export default TEDtitle;
