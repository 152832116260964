import { React, useEffect, useState } from "react";
import Header from "./microComponents/Header.js";
import moment from "moment";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const Talks = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideos = async () => {
      const res = await fetch("https://www.api.tedxoxford.co.uk/getVideos", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const result = await res.json();

      if (result && result.success) {
        setVideos(result.data);
      }
      setLoading(false);
    };

    window.scrollTo(0, 0);
    fetchVideos();
  }, []);

  return (
    <div className="w-back">
      <div className="main-container">
        <Header />
      </div>
      <div className="talks-page">
        <Container className="container-talks-top">
          <div className="container1">
            <Row className="heading align-items-center">
              <Col sm={12} md={9} lg={8} xl={7} className="talks-header">
                <div className="talks-heading">
                  <h1>View all of our talks over the past 11 years.</h1>
                  <p>
                    From Laura Bates talking about the Everyday Sexism project
                    to Richard Layard discussing our basic life purpose, our
                    talks have always aimed to be thought-provoking and
                    informative. Watch and listen to our exceptional speakers
                    and their ground-breaking ideas over the years. Browse all
                    of our talks as well as many more from around the world on
                    TED's Official&nbsp;
                    <a
                      href="https://www.youtube.com/user/TEDtalksDirector"
                      rel="noreferrer"
                      target="_blank"
                      alt="YouTube Link to TED Channel"
                    >
                      YouTube Channel.
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              {loading ? (
                <div class="lds-facebook">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <>
                  {videos.map((video, index) => (
                    <Col
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={4}
                      className="video-holder"
                    >
                      <a
                        href={
                          "https://www.youtube.com/watch?v=" + video.videoID
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="video">
                          <img src={video.thumbnail} alt="Default" />
                          <span className="date">
                            {moment(video.datePublish).format("MMM D YYYY")}
                          </span>
                          <span className="title">{video.title}</span>
                          <span className="speaker">{video.speaker}</span>
                          <span className="description">
                            {video.description}
                          </span>
                        </div>
                      </a>
                    </Col>
                  ))}
                </>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Talks;
