import { useState } from "react";
import Header from "./microComponents/Header.js";
import LayeredWaves from "./microComponents/LayeredWaves.js";
import { db } from "../services/firebase";
import { addDoc, serverTimestamp, collection } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";

const Volunteers = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    experience: "",
    why: "",
    email: "",
    university: "",
    applicationTime: serverTimestamp(),
  });

  const [message, setMessage] = useState();
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    try {
      addDoc(collection(db, "conference", "2023", "volunteers"), formData);
      setSubmitted(true);
    } catch (err) {
      setMessage("Unfortunately, there was an error processing your application, please email us.");
    }
    setMessage("Thanks for your application, we will be in touch soon!")
  };

  const handleInputData = (input) => (e) => {
    const { value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  return (
    <div>
      <div className="main-container">
        <Header />
        <div className="student-speaker">
          <h1>Apply to be a TEDxOxford Volunteer</h1>
          <h4>We are looking for university students!</h4>
          <br />
          <h3>
            Would you like to volunteer at our 2023 event?
          </h3>
          <p>
            Each year, we ask <b>students</b> that are interested in TEDxOxford to help us out at the event with ticketing, selling merchandise and other things. You get an insight into how a group of university students organise the event each year as well as getting a head start on applying to the 2024 committee.
          </p>
          <h4>Do I get anything?</h4>
          <p>
            Free admission to the conference. Since the majority of the work is at the start of the day, you'll have plenty of time to watch some of our amazing talks.
          </p>
          <h4>Do I need any experience?</h4>
          <p>
            No! All you need is to be friendly, helpful and a have a passion for TEDx talks.
          </p>
          <br />
          <hr />
          <h4 className="deadline">
            <span style={{ fontWeight: 600 }}>DEADLINE:</span> 16<sup>th</sup> January
          </h4>
          <hr />
          {submitted === false && (
            <form className="student-apply" onSubmit={handleSubmit}>
              <p className="field-required">
                <span className="red-dot"></span>Field is required
              </p>

              <label>
                First Name:<span className="red-dot special"></span>
              </label>
              <input
                required
                type="text"
                value={formData.firstName}
                name="firstName"
                onChange={handleInputData("firstName")}
              />

              <label>
                Last Name:<span className="red-dot special"></span>
              </label>
              <input
                required
                type="text"
                value={formData.lastName}
                name="lastName"
                onChange={handleInputData("lastName")}
              />

              <label>
                Email:<span className="red-dot special"></span>
              </label>
              <input
                required
                type="email"
                value={formData.email}
                name="email"
                onChange={handleInputData("email")}
              />

              <label>
                University:<span className="red-dot special"></span>
              </label>
              <select
                required
                value={formData.university}
                onChange={handleInputData("university")}
              >
                <option value="">Select University</option>
                <option value="University of Oxford">
                  University of Oxford
                </option>
                <option value="Oxford Brookes">
                  Oxford Brookes University
                </option>
              </select>

              <label>
                Why would you like to apply?:
                <span className="red-dot special"></span>
              </label>
              <textarea
                required
                value={formData.why}
                onChange={handleInputData("why")}
              />

              <label>
                What experience, if any, do you have?:
              </label>
              <textarea
                value={formData.experience}
                onChange={handleInputData("experience")}
              />
              <input type="submit" value="Submit" />
              </form>
            )}
        <div className="student-apply">
          <div className="positive-message">
              {message && <p> {message}</p>}
            </div>
          </div>
        </div>
      </div>
      <LayeredWaves />
    </div>
  );
};
export default Volunteers;
