import React, { useState } from "react";
import TEDtitle from "./TEDtitle.js";

const Newsletter = () => {
  const [subscriber, setSubscriber] = useState({
    EMAIL: "",
    FNAME: "",
    LNAME: "",
    MMERGE4: "",
  });

  const handleInputChange = (event) => {
    const target = event.target;
    const name = target.name;
    setSubscriber({
      [name]: event.target.value,
    });
  };

  return (
    <div className="newsletter">
      <TEDtitle first={"THE"} second={"NEWSLETTER"} />
      <div className="news-container">
        <div className="news-cont">
          <div className="newsletter-signup news">
            <div id="mc_embed_signup">
              <form
                action="https://tedxoxford.us20.list-manage.com/subscribe/post?u=1c1506dcfd6535699f56c2286&amp;id=50110a6d74"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <div id="mc_embed_signup_scroll">
                  {/*<div className="indicates-required"><span className="asterisk">*</span> indicates required</div>*/}
                  <div className="mc-field-group">
                    {/* <label for="mce-EMAIL">Email Address  <span className="asterisk">*</span> 
                      </label>*/}
                    <input
                      type="email"
                      value={subscriber.EMAIL}
                      name="EMAIL"
                      className="required email"
                      id="mce-EMAIL"
                      onChange={(e) => handleInputChange(e)}
                      placeholder="Email"
                    />
                  </div>
                  <div className="mc-field-group">
                    {/*<label for="mce-FNAME">First Name </label> */}
                    <input
                      type="text"
                      value={subscriber.FNAME}
                      name="FNAME"
                      className=""
                      id="mce-FNAME"
                      onChange={(e) => handleInputChange(e)}
                      placeholder="First Name"
                    />
                  </div>
                  <div className="mc-field-group">
                    {/*<label for="mce-LNAME">Last Name </label>*/}
                    <input
                      type="text"
                      value={subscriber.LNAME}
                      name="LNAME"
                      className=""
                      id="mce-LNAME"
                      onChange={(e) => handleInputChange(e)}
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="mc-field-group">
                    {/*<label for="mce-MMERGE3">University  <span className="asterisk">*</span>
                        </label>*/}
                    <select
                      name="MMERGE3"
                      className="required"
                      id="mce-MMERGE3"
                    >
                      <option value="">Choose University</option>
                      <option value="University of Oxford">
                        University of Oxford
                      </option>
                      <option value="Oxford Brookes University">
                        Oxford Brookes University
                      </option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="mc-field-group">
                    {/*<label for="mce-MMERGE4">If you marked other,  please specify here: </label>*/}
                    <input
                      type="text"
                      value={subscriber.MMERGE4}
                      name="MMERGE4"
                      className=""
                      id="mce-MMERGE4"
                      onChange={(e) => handleInputChange(e)}
                      placeholder="If other"
                    />
                  </div>
                  <div id="mce-responses" className="clear">
                    <div
                      className="response"
                      id="mce-error-response"
                      style={{ display: "none" }}
                    ></div>
                    <div
                      className="response"
                      id="mce-success-response"
                      style={{ display: "none" }}
                    ></div>
                  </div>
                  <div
                    style={{
                      display: "none",
                      position: "absolute",
                      left: "-5000px",
                    }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_1c1506dcfd6535699f56c2286_50110a6d74"
                      tabIndex="-1"
                      value=""
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>
                  <div className="clear">
                    <input
                      type="submit"
                      value="SUBSCRIBE"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="news-cont">
          <div className="newsletter-content news">
            <p>
              Subscribe to our newsletter for the latest updates on our events
              plus lots more interesting opportunities and content
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
