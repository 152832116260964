import { React } from "react";
import Header from "./microComponents/Header.js";
import { Container, Row, Col } from "react-bootstrap";
import LayeredWaves from "./microComponents/LayeredWaves.js";
import "bootstrap/dist/css/bootstrap.min.css";

const Committee = () => {
  const committee = [
    {
      name: "Fraser Rennie",
      role: "Co-president",
      image: "it.JPG",
    },
    {
      name: "Jennifer Zhou",
      role: "Co-president",
      image: "jennifer_zhou.jpg",
    },
    {
      name: "Staś Kaleta",
      role: "Head of Speakers",
      image: "stas_kaleta.jpg",
    },
    {
      name: "Julius Chua",
      role: "Speakers Officer",
      image: "julius_chua.jpg",
    },
    {
      name: "Emily Farley",
      role: "Speakers Officer",
      image: "emily_farley.jpg",
    },
    {
      name: "Wayne Gouro",
      role: "Treasurer",
      image: "wayne_gouro.jpg",
    },
    {
      name: "Min Luo",
      role: "Marketing",
      image: "min_luo.jpg",
    },
    {
      name: "Rachel Ojo",
      role: "Sponsorship",
      image: "rachel-ojo.jpg"
    },
    {
      name: "Grisel Jayapurna",
      role: "Creative",
      image: "grisel_jayapurna.jpg",
    },
  ];

  return (
    <div>
      <div className="main-container">
        <Header />
      </div>
      <div className="committee-holder">
        <Container className="committee">
          <div>
            <Row className="">
              <Col sm={12} className="committee-header">
                <h1>Our 2023 Committee</h1>
              </Col>
              {committee.map((data, index) => (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  className="profile-holder"
                  key={index}
                >
                  <div className="profile">
                    <img src={"/members/" + data.image} alt="Default" />
                    <span className="name">{data.name}</span>
                    <span className="role">{data.role}</span>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
      <LayeredWaves />
    </div>
  );
};

export default Committee;
