export const accordionData = [
  {
    title: "What is the TEDxOxford 2023 conference?",
    content: `TEDxOxford 2023 is a one-day conference offering a range of talks on a breadth of subjects with a programme of speakers.`
  },
  {
    title: "When and where is the conference taking place?",
    content: `It will be on the 21<sup>st</sup> of January 2023. The conference itself will take place during the day at Oxford Town Hall.`,
  },
  {
    title: "Who will be speaking during the conference?",
    content: `There will be twelve speakers, two of whom are students, speaking on subjects as diverse as politics, social justice, technology and public health.`,
  },
  {
    title: "What will the schedule be like on the day?",
    content: `The conference will begin at 9am and end at 5pm with an hour of intermission at 12pm. There will also be short breaks throughout the day which will be laid out in the schedule that will be announced soon.`,
  },
  {
    title: "Will there be food?",
    content: `Unfortunately, we cannot provide food and drink although there are shops and restaurants in the immediate vicinity of the Town Hall. There will be a break for lunch at 12pm.`,
  },
  {
    title: "What is your refund policy?",
    content:
      "You can find our refund policy <a href='/refund-policy'>here</a>.",
  }
];

export const lineup = [
  {
    type: "event",
    time: "9:00",
    title: "Doors Open",
  },
  {
    type: "event",
    time: "9:15",
    title: "Welcome",
  },
  {
    type: "talk",
    time: "9:30",
    speaker: "Michael Barton",
    title: "Put Your Money Where Your Mouth Is: Autism and Neurodiversity in the Workplace",
    image: "/speakers/michael-barton.png",
    description: "Michael Barton is an international speaker on autism and neurodiversity. Having published three books on the topic (and selling over 15,000 copies to date) Michael has focused on educating audiences around the world on the positive aspects of being autistic. He has been invited to speak to a wide range of audiences, from global pharmaceutical companies to charities and Primary Schools!",
  },
  {
    type: "talk",
    time: "9:50",
    speaker: "Yang Liu",
    title: "Overcoming Adversity: How Tragedy Can Lead to Triumph",
    image: "/speakers/yang-liu.jpg",
    description:"Yang is the founder and CEO of JustWears - a direct to consumer material science company on the mission to bring biodegradable natural materials and ergonomic designs into everyday men's life, starting from creating the most comfortable underwear for men. Their award-winning Palace for your Phallus® underwear was featured on BBC Dragons' Den, This Morning, Forbes, Evening Standard etc. Yang came to the UK at 23 with nothing but her broken English. She was recently featured on the cover of Forbes 30Under30 Europe for the contribution to sustainability and social impact.",
  },
  {
    type: "talk",
    time: "10:10",
    speaker: "Angus Fletcher",
    title: "One Door, Two: What Special Forces Taught Me About Anger and Anxiety",
    image: "/speakers/angus-fletcher.png",
    description:"Angus Fletcher is Professor of Story Science at Ohio State’s Project Narrative. His research explores why children are more creative than computer AI — and how to use literature, art, and stories to increase innovation, resilience, and joy. His research partners include teams from US Special Operations, UPenn Wharton, Johns Hopkins School of Education, USC Neuroscience, and Ohio State Medical School. His work has been called 'mind-blowing' by Malcolm Gladwell and 'life-changing' by Brene Brown.",
  },
  {
    type: "event",
    time: "10:30",
    title: "Short Break",
  },
  {
    type: "talk",
    time: "10:45",
    speaker: "Tomáš Kočiský",
    title: "Language AI: A Window to All Knowledge",
    image: "/speakers/tomas-kocisky.jpg",
    description: "Tomáš is a researcher in artificial intelligence with particular focus on natural language, and he works as a Staff Research Scientist in the Language team at DeepMind. Tomáš works on teaching agents how to understand language, how to reason about the knowledge we communicate using it, and on evaluating these abilities and their limitations.",
  },
  {
    type: "talk",
    time: "11:05",
    speaker: "Charis Jo",
    title: "Chavrusa: An Ancient Reading Technology for the Modern World",
    image: "/speakers/charis-jo.jpg",
    description: "Charis Jo is a DPhil (PhD) student at the University of Oxford where she is the Graduate Research and Teaching Scholar in Classics at Oriel College. Her academic research focuses on ancient theories of what language is and does: she is especially curious about speech acts, meaning, verbal moods, and the relationship between grammar and psychology. She founded the Oxford Latinitas Project in 2018 which has since become two organizations, the Oxford Ancient Languages Society and Oxford Latinitas, for which she has served as director of vision, academic director, and lead teacher, and for which she now organizes reading groups for school-age girls around the world. She is passionate about philosophy, teaching, mental health, and learning to speak dead languages.",
  },
  {
    type: "talk",
    time: "11:25",
    speaker: "Ivan Shaw",
    title: "A Survivor’s Tale",
    image: "/speakers/Mr-Ivan-Shaw.png",
    description: "Ivan was born Ivan Buchwald in February 1939 in Novi Sad, in former Yugoslavia (now Serbia). In the 1940s, Ivan’s life changed dramatically. He was taken away by the Gestapo to a prison cell where he stayed overnight, alone at just 5 years old. Ivan Shaw is a survivor of the Holocaust and we have the great privilege to have him speak at our conference.",
  },
  {
    type: "event",
    time: "12:00",
    title: "Lunch",
  },
  {
    type: "event",
    time: "13:00",
    title: "Welcome back",
  },
  {
    type: "talk",
    time: "13:15",
    speaker: "Andrew Pollard",
    title: "Are We Prepared?",
    image: "/speakers/sir-andrew-pollard.png",
    description: "Sir Andrew Pollard was the chief investigator for the clinical trials of the Oxford-AstraZeneca vaccine in 2020 with 24,000 participants in the UK, South Africa and Brazil, which led to authorisation of the vaccine for use in more than 180 countries with over 3 billion doses distributed. Professor Pollard is currently the Director of the Oxford Vaccine Group in the Department of Paediatrics at the University of Oxford.",
  },
  {
    type: "talk",
    time: "13:35",
    speaker: "Lin Yue",
    title: "The Art of Being Different: Learn, Unlearn, Relearn",
    image: "/speakers/lin-yue.png",
    description: "Lin is a senior executive working in a major investment bank in London, Goldman Sachs. She has won multiple awards for her work including HERoes 100 Women Future Leaders by Yahoo Finance for four consecutive years, a finalist for Asian Women of Achievement and Women in Finance Award as an advocate in 2021. Lin is also a graduate of experimental psychology from Oxford University focusing on behavioral finance.",
  },
  {
    type: "event",
    time: "13:55",
    title: "Short Break",
  },
  {
    type: "talk",
    time: "14:10",
    speaker: "Olivia Jobe",
    title: "Griots of the Past, Present, and Future",
    image: "/speakers/olivia-jobe.jpg",
    description: "Olivia Aminatta Jobe was born and raised in America by a Black American mother and a Gambian father. She is currently earning a master’s degree in sociology at the University of Oxford. Olivia is a contemporary Griot, collecting the histories and stories of her family members through audio. In 2021, she released the first episode of her podcast, The Griot Podcast for the NPR Podcast Challenge: College Edition. The episode was about her Grandmother’s life story and upbringing in a segregated America. The project inspired her to take a deep dive into her family history using archival methods and online databases.",
  },
  {
    type: "talk",
    time: "14:30",
    speaker: "Yeva Skalietska",
    title: "YOU DON’T KNOW WHAT WAR IS.",
    image: "/speakers/yeva-skalietska.jpg",
    description: "Yeva Skalietska grew up living with her granny in Kharkiv, near the Russian border. Twelve days in Ukraine that changed 12-year-old Yeva's life forever. She was woken in the early hours to the terrifying sounds of shelling. Russia had invaded Ukraine, and her beloved Kharkiv home was no longer a safe haven. It was while she and her granny were forced to seek shelter in a damp, cramped basement that Yeva decided to write down her story. And it is a story that the world needs to hear.",
  },
  {
    type: "event",
    time: "14:50",
    title: "Short Break",
  },
  {
    type: "talk",
    time: "15:05",
    speaker: "Carol Ibe",
    title: "Breaking the Cycle of Hunger and Malnutrition in Africa",
    image: "/speakers/carol-ibe.jpg",
    description: "Carol Ibe is the president and founder of JR biotek Foundation, a non-protit organization dedicated to upskilling and empowering African agricultural scientists to develop high-impact research projects aimed at addressing food insecurity in African countries. Carol now devotes her time, experience and passion towards developing practical agri-tech solutions to enable African scientists to advance research on the genetic improvement of understudied indigenous crops, which hold great promise for closing the hunger and malnutrition gaps in Africa.",
  },
  {
    type: "talk",
    time: "15:25",
    speaker: "Max Fosh",
    title: "How To Get The World’s Most Sought-after Job",
    image: "/speakers/max-fosh.png",
    description: "“Hi, my name is Max Fosh and I am a YouTuber. Well, that is what I am to everyone apart from my immediate family. They would probably class me as unemployed. I have 1.4million subscribers on my channel and my videos have accrued over 350million views worldwide. I started making videos during my final year at University. It started a series called StreetSmart in which I interviewed students on nights out. It was ‘breathtakingly fresh content’ which cemented me as a ‘visionary pioneer’ of the man-on-the-street genre (these could be real quotes, I’m not sure). The real reason I started my own series was mainly because the Uni TV channel already had enough presenters on their show, so I started my own. Following the first episode filmed in October 2017, I relentlessly made StreetSmart episodes and I made approximately 25 episodes before leaving Uni. After graduating, I took StreetSmart on tour round 14 UK Universities. Following this, I worked in a bar for 5 months while working on the channel. I met two YouTubers called Zac and Jay during this time, and in February 2018 we made a video together which went truly viral; I gained 50,000 subscribers virtually overnight. Since then, I have grown steadily and have the greatest job in the world. Being professionally silly.”",
  },
  {
    type: "event",
    time: "15:45",
    title: "End of Conference",
  },
];

export const dinner = [
  {
    type: "event",
    time: "18:30",
    title: "Drinks Reception",
  },
  {
    type: "event",
    time: "19:00",
    title: "Dinner",
  },
  {
    type: "event",
    time: "23:30",
    title: "Carriages",
  },
];



export const speakers = [
  {
    name: "Sir Andrew Pollard",
    tag: "Chief Investigator for Oxford-AstraZeneca",
    image: "sir-andrew-pollard.png",
    desc: "Sir Andrew Pollard was the chief investigator for the clinical trials of the Oxford-AstraZeneca vaccine in 2020 with 24,000 participants in the UK, South Africa and Brazil, which led to authorisation of the vaccine for use in more than 180 countries with over 3 billion doses distributed. Professor Pollard is currently the Director of the Oxford Vaccine Group in the Department of Paediatrics at the University of Oxford."
  },
  {
    name: "Yeva Skalietska",
    image: "yeva-skalietska.jpg",
    tag: "Ukrainian Refugee",
    desc: "Yeva Skalietska grew up living with her granny in Kharkiv, near the Russian border. Twelve days in Ukraine that changed 12-year-old Yeva's life forever. She was woken in the early hours to the terrifying sounds of shelling. Russia had invaded Ukraine, and her beloved Kharkiv home was no longer a safe haven. It was while she and her granny were forced to seek shelter in a damp, cramped basement that Yeva decided to write down her story. And it is a story that the world needs to hear."
  },
  {
    name: "Tomáš Kočiský",
    image: "tomas-kocisky.jpg",
    tag: "AI Researcher",
    desc: "Tomáš is a researcher in artificial intelligence with particular focus on natural language, and he works as a Staff Research Scientist in the Language team at DeepMind. Tomáš works on teaching agents how to understand language, how to reason about the knowledge we communicate using it, and on evaluating these abilities and their limitations."
  },
  {
    name: "Charis Jo",
    tag: "Student Speaker",
    image: "charis-jo.jpg",
    desc: "Charis Jo is a DPhil (PhD) student at the University of Oxford where she is the Graduate Research and Teaching Scholar in Classics at Oriel College. Her academic research focuses on ancient theories of what language is and does: she is especially curious about speech acts, meaning, verbal moods, and the relationship between grammar and psychology. She founded the Oxford Latinitas Project in 2018 which has since become two organizations, the Oxford Ancient Languages Society and Oxford Latinitas, for which she has served as director of vision, academic director, and lead teacher, and for which she now organizes reading groups for school-age girls around the world. She is passionate about philosophy, teaching, mental health, and learning to speak dead languages."
  },
  {
    name: "Olivia Jobe",
    tag: "Student Speaker",
    image: "olivia-jobe.jpg",
    desc: "Olivia Aminatta Jobe was born and raised in America by a Black American mother and a Gambian father. She is currently earning a master’s degree in sociology at the University of Oxford. Olivia is a contemporary Griot, collecting the histories and stories of her family members through audio. In 2021, she released the first episode of her podcast, The Griot Podcast for the NPR Podcast Challenge: College Edition. The episode was about her Grandmother’s life story and upbringing in a segregated America. The project inspired her to take a deep dive into her family history using archival methods and online databases."
  },
  {
    name: "Carol Ibe",
    tag: "Founder of JR Biotek Foundation",
    image: "carol-ibe.jpg",
    desc: "Carol Ibe is the president and founder of JR biotek Foundation, a non-protit organization dedicated to upskilling and empowering African agricultural scientists to develop high-impact research projects aimed at addressing food insecurity in African countries. Carol now devotes her time, experience and passion towards developing practical agri-tech solutions to enable African scientists to advance research on the genetic improvement of understudied indigenous crops, which hold great promise for closing the hunger and malnutrition gaps in Africa."
  },
  {
    name: "Ivan Shaw",
    image: "Mr-Ivan-Shaw.png",
    tag: "Holocaust Survivor",
    desc: "Ivan was born Ivan Buchwald in February 1939 in Novi Sad, in former Yugoslavia (now Serbia). In the 1940s, Ivan’s life changed dramatically. He was taken away by the Gestapo to a prison cell where he stayed overnight, alone at just 5 years old. Ivan Shaw is a survivor of the Holocaust and we have the great privilege to have him speak at our conference."
  },
  {
    name: "Lin Yue",
    image: "lin-yue.png",
    tag: "Senior Executive at Goldman Sachs",
    desc: "Lin is a senior executive working in a major investment bank in London, Goldman Sachs. She has won multiple awards for her work including HERoes 100 Women Future Leaders by Yahoo Finance for four consecutive years, a finalist for Asian Women of Achievement and Women in Finance Award as an advocate in 2021. Lin is also a graduate of experimental psychology from Oxford University focusing on behavioral finance."
  },
  {
    name: "Angus Fletcher",
    image: "angus-fletcher.png",
    tag: "Professor of Story Science",
    desc: "Angus Fletcher is Professor of Story Science at Ohio State’s Project Narrative. His research explores why children are more creative than computer AI — and how to use literature, art, and stories to increase innovation, resilience, and joy. His research partners include teams from US Special Operations, UPenn Wharton, Johns Hopkins School of Education, USC Neuroscience, and Ohio State Medical School. His work has been called 'mind-blowing' by Malcolm Gladwell and 'life-changing' by Brene Brown."
  },
  {
    name: "Yang Liu",
    image: "yang-liu.jpg",
    tag: "Founder and CEO of JustWears",
    desc: "Yang is the founder and CEO of JustWears - a direct to consumer material science company on the mission to bring biodegradable natural materials and ergonomic designs into everyday men's life, starting from creating the most comfortable underwear for men. Their award-winning Palace for your Phallus® underwear was featured on BBC Dragons' Den, This Morning, Forbes, Evening Standard etc. Yang came to the UK at 23 with nothing but her broken English. She was recently featured on the cover of Forbes 30Under30 Europe for the contribution to sustainability and social impact."
  },
  {
    name: "Michael Barton",
    image: "michael-barton.png",
    tag: "Autism and Neurodiversity Speaker",
    desc: "Michael Barton is an international speaker on autism and neurodiversity. Having published three books on the topic (and selling over 15,000 copies to date) Michael has focused on educating audiences around the world on the positive aspects of being autistic. He has been invited to speak to a wide range of audiences, from global pharmaceutical companies to charities and Primary Schools!"
  },
]